import React from 'react';
// scss
import './src/styles/style.scss';
import { ActionContextProvider, createConfig } from './src/cra';
export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Medicare FAQ',
  });
  return <ActionContextProvider>{element}</ActionContextProvider>;
};
